/* font-family: "Bebas Neue", sans-serif; 
font-family: "Lato", sans-serif;
*/

@font-face {
  font-family: "kenyancoffeerg";
  src: local("kenyancoffeerg"),
    url("../src/thinktrek/fonts/kenyancoffeerg.ttf") format("opentype");
}

body {
  margin: 0 auto;
  padding: 0px;
}

.header_sec {
  width: 100%;
  box-shadow: 0px 10px 25px #9698A730;
  background: #fff;
  padding: 15px 0;
  position: fixed;
  z-index: 3;
}

.header_inn {
  width: 100%;
}

.header_inn .navbar-nav li a {
  font: 400 37px/40px "Bebas Neue", sans-serif;
  color: #1D222B;
  padding: 0px;
  cursor: pointer;
}

.header_inn .navbar-nav li {
  padding: 0 15px;
}

.banner_sec {
  width: 100%;
  padding: 200px 0 40px;
}

.main_banner {
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding: 40px 0;
  margin-top: 40px;
}

.main_ban_lt {
  width: 100%;
  padding-right: 50px;
}

.main_ban_rt {
  width: 100%;
  padding-top: 55px;
  position: relative;
  text-align: center;
}

.main_ban_rt h6 {
  margin: 0px;
  font: 400 45px/50px 'kenyancoffeerg';
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.sub_modal .modal-header {
  border: none;
}

.sub_modal .modal-footer {
  border: none;
}

.sub_modal .modal-body h6 {
  font: 500 60px / 70px "Bebas Neue", sans-serif;
  text-align: center;
}

.sub_modal .modal-body h6 span {
  color: #FC9000;
}

.sub_modal .modal-body p {
  font: 400 17px / 22px "Lato", sans-serif;
  text-align: center;
}

.sub_modal .btn-close {
  opacity: 1;
}

.main_ban_rt h2 {
  margin: 20px 0 0;
  font: 400 85px/90px 'kenyancoffeerg';
  color: #FFC312;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 5px 0px #0D0616;
    -webkit-text-stroke: 2px #0D0616;
}

.lakh_sec {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 15px;
}

.lakh_sec img {
  width: 100%;
}

.ob1_sec {
  position: absolute;
  top: 22px;
  left: -14px;
  width: 28px !important;
  height: 28px;
}

.shopping_sec {
  width: 100%;
  margin-top: -40px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.play_store_sec {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.play_store_sec img {
  width: 180px;
}

.ob2_sec {
  position: absolute;
  left: 0px;
  top: 25px;
}

.ob3_sec {
  position: absolute;
  right: 0px;
  top: -40px;
}

.ob4_sec {
  position: absolute;
  left: -167px;
  bottom: 0px;
  z-index: 0;
}

.ob5_sec {
  position: absolute;
  right: -57px;
  bottom: 9px;
  z-index: 0;
}

.ban_lt {
  width: 100%;
}

.ban_rt {
  width: 100%;
}

.ban_rt h3 {
  margin: 0px;
  padding: 20px 0 0 150px;
  font: 500 70px/80px "Bebas Neue", sans-serif;
  color: #1D222B;
}

.about_sec {
  width: 100%;
  padding: 75px 0 45px;
}

.about_inn {
  width: 100%;
}

.about_inn h5 {
  margin: 0px;
  font: 500 70px/80px "Bebas Neue", sans-serif;
  color: #FC9000;
  text-align: center;
  text-transform: uppercase;
}

.about_inn h5 span {
  color: #1D222B;
}

.about_blog {
  width: 100%;
  padding: 100px 100px 0 0;
}

.about_blog p {
  color: #1D222B;
  font: 400 24px/32px "Lato", sans-serif;
  padding: 30px 0 0;
  margin: 0px;
}

.about_img {
  width: 100%;
  padding-top: 50px;
}

.betsto_win_sec {
  width: 100%;
  background-size: cover !important;
  padding: 100px 0 60px;
}

.betstowin_inn {
  width: 100%;
  text-align: center;
}

.betstowin_inn img {
  width: 500px;
}

.betstowin_inn h4 {
  font: 400 87px/90px "kenyancoffeerg";
  margin: 0px;
  color: #fff;
  padding-top: 22px;
}

.betstowin_inn h4 span {
  color: #017CF3;
}

.betstowin_inn p {
  margin: 0px;
  font: 500 22px/32px "Lato", sans-serif;
  color: #fff;
  padding-top: 25px;
}

.bet_game {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
}

.bet_game_in {
  width: auto
}

.bet_game_in img {
  width: 148px;
}

.bet_game_in p {
  font: 500 25px/32px "kenyancoffeerg";
  color: #fff;
}

.knowmore {
  width: 100%;
  padding-top: 60px !important;
  margin: 0px;
  text-align: center;
}

.knowmore a {
  font: 500 35px/40px "Bebas Neue", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.virtual_lt {
  width: 100%;
  height: 749px;
  background-size: contain !important;
  background-position: bottom;
  background-repeat: no-repeat;
}

.virtual_lt img {
  width: 100%;
}

.footer_sec {
  width: 100%;
  padding: 80px 0 30px;
  background: #1D222B;
}

.footer_top {
  width: 100%;
}

.footer_top h6 {
  margin: 0px;
  font: 700 24px/32px "Lato", sans-serif;
  color: #fff;
  position: relative;
}

.footer_top h6::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: -15px;
  width: 37px;
  height: 3px;
  background: #FC9000;
  border-radius: 2px;
}

.footer_logo {
  width: 100%;
  margin-bottom: 55px;
}

.footer_btm {
  width: 100%;
  padding-top: 15px;
}

.footer_btm p {
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0px;
  color: #fff;
  font: 300 16px/20px "Lato", sans-serif;
  padding-top: 25px;
}

.footer_btm p span i {
  font-size: 14px;
  margin-right: 10px;
}

.footer_btm ul {
  margin: 0px;
  padding: 30px 0 0;
  list-style-type: none;
}

.footer_btm ul li {
  width: 100%;
  padding: 0px 0 11px;
}

.footer_btm ul li a {
  font: 300 16px/20px "Lato", sans-serif;
  color: #fff;
  text-decoration: none;
  padding-left: 0px;
  padding-top: 0px;
}
.footer_btm ul li a:hover{color: #FC9000;}

.foot1 {
  width: 100%;
  padding-bottom: 26px;
  position: relative;
}

.foot1 input[type="text"],
input[type="email"] {
  width: 100%;
  background:rgb(95 106 122 / 38%);
  border-radius: 10px;
  height: 40px;
 
  border: none;
  padding: 0 10px;
  color: #fff;
  font: 300 16px/20px "Lato", sans-serif;
}

.foot1 input::placeholder {
  color: rgba(132, 140, 151, 0.74);/* placeholder color with 38% opacity */
}

.foot1 textarea::placeholder {
  color: rgba(132, 140, 151, 0.74);/* placeholder color with 38% opacity */
}

.foot1 textarea {
  width: 100%;
  background:rgb(95 106 122 / 38%);
  border-radius: 10px;
  height: 80px;
  border: none;
  padding: 10px 10px;
  color: #fff;
  
  font: 300 16px/20px "Lato", sans-serif;
}

.support {
  width: 100%;
  padding-top: 48px;
}

::placeholder {
  color: #FFFFFF;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #FFFFFF;
}

:focus-visible {
  outline: none;
}

.foot1 input[type="button"] {
  width: 100%;
  margin-top: 20px;
  background: #FC9000;
  border-radius: 7px;
  border: none;
  font: 400 20px/24px "Lato", sans-serif;
  text-transform: uppercase;
  padding: 16px 0;
  color: #fff;
}

.footer_dwn {
  width: 100%;
  border-top: 2px solid #5F6A7A;
  padding: 20px 0 0;
  margin-top: 25px;
  text-align: center;
}

.footer_dwn p {
  font: 300 15px/20px "Lato", sans-serif;
  color: #fff;
  margin: 0px;
}

.mob_off {
  display: block;
}

.mob_on {
  display: none;
}

.virtual_rt {
  width: 100%;
  text-align: center;
  padding-top: 60px;
}

.virtual_rt h6 {
  font: 600 40px/50px "Bebas Neue", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0px;
}

.virtual_rt h5 {
  font: 600 100px/90px "Bebas Neue", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0px;
}

.virtual_rt h2 {
  font: 600 180px/160px "Bebas Neue", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0px;
}

.virtual_btm {
  width: 100%;
  padding-top: 70px;
}

.virtual_top {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #cbc1ce;
  position: relative;
  text-align: center;
  display: inline-block;
}

.virtual_top img {
  position: absolute;
  top: 23%;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: auto;
}

.virtual_sec {
  width: 100%;
  background-size: cover !important;
  padding-top: 20px;
}

.virtual_btm p {
  font: 500 25px/32px "kenyancoffeerg";
  color: #fff;
  margin: 0px;
  text-align: center;
}

.think {
  padding: 0px;
  margin: 0px;
  position: relative;
}

.play_now {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  text-align: center;
}

.play_now a {
  font: 500 35px / 40px "Bebas Neue", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
    bottom: 17px;
    left: 0px; right: 0px;
}

.mob_back {
  width: 100%;
  background: #062C55;
  padding: 15px 0;
  margin-top: 20px;
}

.mob_back h6 {
  text-align: center;
  margin: 0px;
  font: 400 37px / 40px "Bebas Neue", sans-serif;
  color: #fff;
}

.mob_playz {
  width: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding: 40px 15px;

  text-align: center;
}
.mob_playz_logo{width: 250px; display: inline-block;}
.mob_playz_logo img{width: 100%;}
.mb1{width: 100%; padding-top: 70px;}
.mb1 img{width: 100%;}
.mob_playz .play_now{position: relative;}
#playzhub{display: block;}
#playzhub1{display: none;}
.navbar-light .navbar-toggler{border: none !important;}
.navbar-nav{padding-top: 15px;}
.header_inn .navbar-nav li a{font-size: 25px; line-height: 30px;}
button:focus{outline: none !important; box-shadow: none !important;}
.err_fil{position: absolute; font-size: 12px; bottom: 3px; width: 100%;  left: 11px;}


.footer_priv{width:100%; padding-top: 70px;}
.footer_priv ul{list-style-type: none; padding: 0px; margin: 0px;}
.footer_priv ul li{padding: 0px 0 11px;}
.footer_priv ul li a{ font: 300 16px / 20px "Lato", sans-serif; color: #fff !important; text-decoration: none !important; cursor: pointer;}
.footer_priv ul li a:hover{color: #FC9000 !important;}
.main_privacy{width: 100%; padding: 150px 0 30px;}
.privacy_hed{width: 100%; margin-bottom: 30px;}
.privacy_hed h3{margin: 0px; font-size: 30px; line-height: 40px; color: #000; text-align: center;}
.privacy_mid{width: 100%; margin-top: 20px;}
.privacy_mid_inn{width: 100%; padding: 15px 0;}
.privacy_mid_inn h6{margin: 0px; font: 600 22px / 25px "Lato", sans-serif; padding-bottom: 10px;}
.privacy_mid_inn p{font: 400 18px / 24px "Lato", sans-serif;}
.privacy_mid_inn p span{text-decoration: underline;}
.terms_mid{width: 100%; margin-top: 20px;}
.terms_mid_inn{width: 100%; padding: 15px 0;}
.terms_mid_inn p{font: 400 18px / 24px "Lato", sans-serif;}
.terms_mid_inn h6{margin: 0px; font: 600 22px / 25px "Lato", sans-serif; padding-bottom: 10px;}




@media (min-width:992px) and (max-width: 1199px) {
  .ob5_sec {
    right: -26px;
  }

  .shopping_sec img {
    width: 83%;
  }

  .ban_rt h3 br {
    display: none;
  }

  .ban_rt h3 {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (min-width:768px) and (max-width: 991px) {
.footer_top h6{font-size: 16px; line-height: 20px;}
}

@media only screen and (max-width: 600px) {
  .bet_game {
    display: inline-block;
  }

  .footer_logo img{width: 300px;}

  .bet_game_in {
    width: 49%;
    float: left;
  }

  .betstowin_inn img {
    width: 75%;
  }

  .betstowin_inn h4 {
    font: 400 55px/66px "kenyancoffeerg";
  }

  .about_blog {
    padding: 20px 0;
  }

  .mob_off {
    display: none;
  }

  .mob_on {
    display: block;
  }

  .virtual_lt {
    height: 440px;
  }

  .ban_rt h3 {
    padding-left: 15px;
    font-size: 45px;
    line-height: 48px;
  }

  .ban_rt h3 br {
    display: none;
  }

  .shopping_sec img {
    width: 100%;
  }

  .ob5_sec {
    right: 0px;
    width: 10%;
  }

  .about_img img {
    width: 80% !important;
  }

  .about_img {
    text-align: center;
  }

  .virtual_rt h2 {
    font: 600 80px / 75px "Bebas Neue", sans-serif;
  }

  .virtual_rt h5 {
    font: 600 80px / 90px "Bebas Neue", sans-serif;
  }

  .navbar-brand img {
    width: 175px;
  }

  .play_now {
    bottom: 0px;
  }

 
  .mob_playz .play_now{ font: 500 35px / 40px "Bebas Neue", sans-serif;}
  .footer_lt{margin-top: 12px;}
  .footer_sec{padding: 50px 0 30px;}
  .virtual_top{margin-top: 15px;}
  .virtual_btm{padding-top: 30px;}
}


@media only screen and (max-width: 768px) {
#playzhub1{display: block;}
  #playzhub{display: none;}
}